import React from "react";

import './styles.scss';

const NotFound = ({ }) => (
  <section className="ams-404">
    <div className="ams-404__container">
      <h1>404</h1>
      <p>Testing...</p>
    </div>
  </section>
);

export default NotFound;
